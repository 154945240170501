import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Acta Futura",
  "banner": "/images/core/pub_banner.jpg",
  "banner_caption": "Resources"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Obfuscate = makeShortcode("Obfuscate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Acta Futura is an open access on-line journal run by scientists connected with the Advanced Concepts Team. It publishes papers on multidisciplinary research topics of potential interest in the long term for space science.
We hope that we will be able to look back to this publication in many years to find the seeds of the ideas that revolutionized space science.`}</p>
    <hr></hr>
    <h3>{`Journal Scope:`}</h3>
    <p>{`Acta Futura is an electronic journal covering multidisciplinary research topics of potential interest in the long term for space science. The journal contains papers covering topics in which the space sector has a demonstrated interest. Thus, the content
includes a wide spectrum of scientific areas, ranging from fundamental physics to biomimetics, mission analysis, computational intelligence, neuroscience, as well as artificial intelligence or energy systems; this list is not exhaustive.`}</p>
    <h3>{`How to submit a paper to Acta Futura:`}</h3>
    <p>{`To submit a paper to Acta Futura the interested Author(s) need to send a "paper proposal" (1-2 pages) to `}<Obfuscate email="acta_futura@esa.int" mdxType="Obfuscate" />{` which includes a discussion on the impact of its content on space sciences.
Note that invited authors are not required to submit a paper proposal. Upon receiving a paper proposal, the editors will decide whether such a paper fits the scope of the journal, and will inform the authors of their decision. In the
case of a positive decision being made, the authors will be asked to write and submit the proposed paper. A peer-review process, under the responsibility of the assigned editor, will then decide upon the final acceptance of the papers.
Accepted papers will then be published in a future issue. No fees of any kind (processing, publishing, etc.) need to be paid for publishing in Acta Futura.`}</p>
    <h3>{`Submission guidelines:`}</h3>
    <p>{`The "paper proposal" needs to be sent in pdf format (no particular formatting is required). The final production files need to be written in LaTex (no other forms will be accepted), modifying the LaTex template provided below. The use of BibTex is required. The use of the following packages is to be avoided: tikz, subfigure, subfig, pstricks, psfrag. In general, the use of additional packages is deprecated. All images have to be either pdf, eps or png. All files have to be submitted in zip archive as to avoid UTF-8 compatibility issues. Only the strictly necessary files need to be submitted, i.e no .bbl, .aux .tmp, unused figures etc.`}</p>
    <p>{`Download the `}<a parentName="p" {...{
        "href": "/gsp/ACT/doc/ACTAFUTURA/acta_futura_template.zip"
      }}>{`Acta Futura LaTex Template`}</a></p>
    <h3>{`Licence and Copyright:`}</h3>
    <p>{`Acta Futura applies the Creative Commons Attribution (CC BY) license to all works published (find here the `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/"
      }}>{`human-readable summary`}</a>{` or the `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by/4.0/legalcode"
      }}>{`full license`}</a>{`).
By agreeing to publish with Acta Futura you are agreeing to the terms of this licencing agreement.`}</p>
    <p>{`Under the CC BY license, authors retain ownership of the copyright for their article, but authors allow anyone to download, reuse, reprint, modify, distribute, and/or copy articles in Acta Futura, so long as the original authors and
source are cited. No permission is required from the authors or the publishers.`}</p>
    <p>{`In most cases, appropriate attribution can be provided by simply citing the original article. If the item you plan to reuse is not part of a published article (e.g., a featured issue image), then please indicate the originator of the work, and the volume, issue, and date of the journal in which the item appeared. For any reuse or redistribution of a work, you must also make clear the license terms under which the work was published.`}</p>
    <p>{`This broad license was developed to facilitate open access to, and free use of, original works of all types. Applying this standard license to your own work will ensure your right to make your work freely and openly available.`}</p>
    <h3>{`Publication Ethics and Malpractice Statement:`}</h3>
    <p>{`Acta Futura is a (mainly) electronic peer reviewed international journal committed to upholding the highest standards of publication ethics. In order to provide our readers with a journal of highest quality we state the following principles of publication ethics and malpractice. All articles not in accordance with these standards will be removed from the publication at any time these are found, even after the publication. Acta Futura is checking all papers for plagiats and research fabrication (making up research data); falsification (manipulation of existing research data, tables, or images) and improper use of humans or animals in research. In accordance with the code of conduct we will report any cases of suspected plagiarism or duplicate publishing. The journal Acta Futura reserves the right to use plagiarism detecting software to screen submitted papers at all times.`}</p>
    <p>{`Authors must ensure that they have written original works. In addition they must ensure that the manuscript has not been issued elsewhere. Any work or words of other authors, contributors, or sources should be appropriately credited
and referenced. Authors are also responsible for language editing before submitting the article. Authors submitting their works to the journal for publication as original articles confirm that the submitted works represent their authors’
contributions and have not been copied or plagiarized in whole or in part from other works without clearly citing. Any work or words of other authors, contributors, or sources (including online sites) should be appropriately credited
and referenced. All authors should disclose financial or other conflict of interest that might influence the results or interpretation of their manuscript (financial support for the project should be disclosed). When an author discovers
a significant error or inaccuracy in his/her own published work, it is the author’s obligation to promptly notify the journal editor and cooperate with the editor to retract or correct the paper. An author agrees to the license agreement
before submitting the article. All articles must be submitted using online submission procedure. Submitting a paper simultaneously to more than one publication at a time is a breach of publications ethics.`}</p>
    <p>{`Editors must ensure a fair review of the submitted articles for publication. They will strive to prevent any potential conflict of interests between the author and editorial and review personnel. Editors will also ensure that all the
information related to submitted manuscripts is kept as confidential before publishing. Editor-in-Chief will coordinate the work of the editors.`}</p>
    <p>{`Reviewers evaluate manuscripts based on content without regard to ethnic origin, gender, sexual orientation, citizenship, religious belief or political philosophy of the authors. They must ensure that all the information related to submitted manuscripts is kept as confidential and must report to the Editor-in-Chief if they are aware of copyright infringement and plagiarism on the author’s side. They must evaluate the submitted works objectively as well as present clearly their opinions on the works in a clear way in the review form. A reviewer who feels unqualified to review the research reported in a manuscript or knows that its prompt review will be impossible should notify the Editor-in-Chief and excuse himself from the review process.`}</p>
    <h3>{`Editors in Chief:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.researchgate.net/profile/Dario_Izzo"
        }}>{`Dario Izzo`}</a>{` (European Space Agency)`}</li>
    </ul>
    <h3>{`Editors:`}</h3>
    <p>{`All members of the Advanced Concepts Team are editor to the Acta Futura Journal`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      